import axios from './axios'
import AbstractApi from './AbstractApi'
import { getHeaders } from '../utils/functions/apiUtils'
import { COLLECTION_LOCATION_URL } from './apiConstants'

class CollectionLocation extends AbstractApi {
  constructor() {
    super(COLLECTION_LOCATION_URL)
  }

  calculateCoordinates = ({ obj, token }) =>
    axios.post(`${this.url}/coordinates`, obj, getHeaders(token))

  findAllGroups = ({ token }) =>
    axios.get(`${this.url}/groups`, getHeaders(token))
}

export default new CollectionLocation()
