import axios from './axios'
import AbstractApi from './AbstractApi'
import { ZONE_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class Zone extends AbstractApi {
  constructor() {
    super(ZONE_URL)
  }

  findByIdWithParams = ({ token, _id, params }) =>
    axios.get(`${this.url}/${_id}`, getHeaders(token, params))
}

export default new Zone()
