import AbstractApi from './AbstractApi'
import { PRICING_URL } from './apiConstants'

class Pricing extends AbstractApi {
  constructor() {
    super(PRICING_URL)
  }
}

export default new Pricing()
