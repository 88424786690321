import { useState } from 'react'

export const useSteps = (steps) => {
  const [currentStep, setCurrentStep] = useState(0)

  const goNext = () => setCurrentStep((curr) => curr + 1)

  const goBack = () => setCurrentStep((curr) => curr - 1)

  const resetStep = () => setCurrentStep(0)

  const isLastStep = currentStep === steps.length - 1

  const currentStepObj = steps[currentStep]

  return { goNext, goBack, resetStep, isLastStep, currentStepObj, currentStep }
}
