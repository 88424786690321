import AbstractApi from './AbstractApi'
import { DEDICATED_DELIVERY_PLAN_URL } from './apiConstants'

class DedicatedDeliveryPlan extends AbstractApi {
  constructor() {
    super(DEDICATED_DELIVERY_PLAN_URL)
  }
}

export default new DedicatedDeliveryPlan()
