import { notification } from 'antd'

const placement = 'bottomRight'

export const successNotification = (description, { preventDuplicated } = {}) =>
  notification.open({
    key: preventDuplicated ? description : undefined,
    type: 'success',
    message: 'Success',
    description,
    placement,
  })

export const infoNotification = (description, { preventDuplicated } = {}) =>
  notification.open({
    key: preventDuplicated ? description : undefined,
    type: 'info',
    message: 'Info',
    description,
    placement,
  })

export const warningNotification = (description, { preventDuplicated } = {}) =>
  notification.warning({
    key: preventDuplicated ? description : undefined,
    type: 'warning',
    message: 'Warning',
    description,
    placement,
  })

export const errorNotification = (error, { preventDuplicated } = {}) => {
  const typeError = error?.response?.data?.type || undefined
  const description = error.response
    ? error.response.data.message
    : error.message

  if (typeError === 'info')
    warningNotification(description, { preventDuplicated })
  else
    notification.open({
      key: preventDuplicated ? description : undefined,
      type: 'error',
      message: 'Error',
      description,
      placement,
    })
}

export const customSuccessNotification = (description, duration, placement) =>
  notification.success({ message: 'Success', description, duration, placement })
