import { useState, useEffect } from 'react'

import { errorNotification } from './actionNotifications'
import { isFunction } from './typesFunctions'
import { isUnauthorizedError } from './httpFunctions'

export const useGet = (
  axiosFunc,
  variables,
  {
    isObj,
    cancelFirstEffect,
    cancelErrorNotification,
    onError,
    preventDuplicatedErrorNotification,
  } = {},
) => {
  const defData = isObj ? {} : []

  const [req, setReq] = useState({
    data: defData,
    loading: false,
    error: false,
  })

  useEffect(() => {
    if (!cancelFirstEffect) getData()
    // eslint-disable-next-line
  }, [cancelFirstEffect])

  const getData = async (newVariables) => {
    setReq({ data: defData, loading: true, error: false })
    try {
      const { data } = await axiosFunc(newVariables || variables)
      setReq({ data, loading: false, error: false })
    } catch (error) {
      const isCancelErrorNotification = isFunction(cancelErrorNotification)
        ? cancelErrorNotification(error)
        : cancelErrorNotification
      if (!isCancelErrorNotification) {
        errorNotification(error, {
          preventDuplicated:
            preventDuplicatedErrorNotification || isUnauthorizedError(error),
        })
      }
      setReq({ data: defData, loading: false, error })
      if (onError) onError(error)
    }
  }

  return { ...req, refetch: getData }
}

export const useGetReactive = (
  axiosFunc,
  variables,
  {
    onCompleted,
    onError,
    cancelErrorNotification,
    cancelFirstEffect,
    preventDuplicatedErrorNotification,
  } = {},
) => {
  const [req, setReq] = useState({ loading: false, error: false })

  useEffect(() => {
    if (!cancelFirstEffect) getData()
    // eslint-disable-next-line
  }, [cancelFirstEffect])

  const getData = async (newVariables) => {
    setReq({ loading: true, error: false })
    try {
      const { data } = await axiosFunc(newVariables || variables)
      setReq({ loading: false })
      onCompleted({ data })
    } catch (error) {
      const isCancelErrorNotification = isFunction(cancelErrorNotification)
        ? cancelErrorNotification(error)
        : cancelErrorNotification
      if (!isCancelErrorNotification) {
        errorNotification(error, {
          preventDuplicated:
            preventDuplicatedErrorNotification || isUnauthorizedError(error),
        })
      }
      setReq({ loading: false, error })
      if (onError) onError(error)
    }
  }

  return { ...req, refetch: getData }
}

export const useMutation = (axiosFunc, { onCompleted, onError } = {}) => {
  const defData = undefined
  const [req, setReq] = useState({ loading: false, error: false })

  const execFunction = async (variables) => {
    setReq({ loading: true, error: false })
    try {
      const data = await axiosFunc(variables)
      setReq({ data: data?.data, loading: false })
      if (onCompleted) onCompleted({ data: data?.data, variables })
      return data
    } catch (error) {
      errorNotification(error)
      setReq({ loading: false })
      if (onError) onError(error)
      return defData
    }
  }

  return [execFunction, { ...req }]
}
