import user from './User'
import truck from './Truck'
import collectionLocation from './CollectionLocation'
import collectionLocationHistory from './CollectionLocationHistory'
import store from './Store'
import donor from './Donor'
import donation from './Donation'
import organization from './Organization'
import hub from './Hub'
import scalar from './Scalar'
import driver from './Driver'
import role from './Roles'
import country from './Country'
import donationItems from './DonationItems'
import postalCode from './PostalCode'
import calendar from './Calendar'
import importData from './ImportData'
import route from './Route'
import donationReport from './DonationReport'
import publicRoute from './Public'
import holiday from './Holiday'
import zone from './Zone'
import region from './Region'
import dedicatedDeliveryPlan from './DedicatedDeliveryPlan'
import osd from './Osd'
import pricing from './Pricing'
import inventory from './Inventory'

const api = {
  user,
  truck,
  collectionLocation,
  collectionLocationHistory,
  organization,
  store,
  donor,
  donation,
  hub,
  scalar,
  driver,
  role,
  country,
  donationItems,
  postalCode,
  calendar,
  importData,
  route,
  donationReport,
  publicRoute,
  holiday,
  zone,
  region,
  dedicatedDeliveryPlan,
  osd,
  pricing,
  inventory,
}

export default api
