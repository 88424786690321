import { Link, useLocation, Route } from 'react-router-dom'
import { Menu } from 'antd'
const { Item, SubMenu } = Menu

export const generateLinks = (
  routes,
  isSider,
  formatMessage,
  isFullPermisions,
  resources,
) =>
  routes &&
  routes.map((item) => {
    const _subMenuRoutes =
      (item.subMenuRoutes && [...item?.subMenuRoutes]) || []

    let filterSubMenuRoutes = _subMenuRoutes.filter(
      (menuRoute) => isFullPermisions || resources.includes(menuRoute.name),
    )

    if (!item?.hidden && !item?.subMenuRoutes)
      return (
        resources.includes(item.name) ||
        (item.name === 'home' && (
          <Item key={item.path}>
            <Link to={item.path}>
              {item.icon}
              <span
                style={{ fontWeight: '500' }}
                className={isSider ? '' : 'd-none d-sm-inline ml-2'}
              >
                {formatMessage({ id: item.name })}
              </span>
            </Link>
          </Item>
        ))
      )

    return (
      filterSubMenuRoutes.length > 0 && (
        <SubMenu
          key={item.path}
          icon={item.icon}
          title={formatMessage({ id: item.name })}
          style={{ fontWeight: '500' }}
        >
          {filterSubMenuRoutes.map(
            (subItem) =>
              !subItem.hidden && (
                <Item key={subItem.path}>
                  <Link to={subItem.path}>
                    {subItem.icon}
                    <span
                      style={{ fontWeight: '500' }}
                      className={isSider ? '' : 'd-none d-sm-inline ml-2'}
                    >
                      {formatMessage({ id: subItem.name })}
                    </span>
                  </Link>
                </Item>
              ),
          )}
        </SubMenu>
      )
    )
  })

export const generateRoutes = (routes, isFullPermisions, resources) =>
  routes &&
  routes.map((item) => {
    const _subMenuRoutes =
      (item.subMenuRoutes && [...item?.subMenuRoutes]) || []

    let filterSubMenuRoutes = _subMenuRoutes.filter(
      (menuRoute) => isFullPermisions || resources.includes(menuRoute.name),
    )

    if (!filterSubMenuRoutes.length) {
      return (
        <Route key={item.path} path={item.path} component={item.component} />
      )
    }

    return (
      <Route
        key={item.path}
        path={item.path}
        render={() =>
          filterSubMenuRoutes.map((elem) => (
            <Route
              key={elem.path}
              path={elem.path}
              component={elem.component}
            />
          ))
        }
      />
    )
  })

export const useActivePaths = () => [useLocation().pathname]

export const useActivePathsToBreadcrumb = () => {
  const res = useLocation().pathname.replaceAll(/(\d)\w+/g, '')
  return res.split('/')
}

export const getPathWithoutParam = (path) => path.split(':').shift()

export const findActiveTab = (accessRoutes, activePath) => {
  const withoutParamsRoutes = accessRoutes.map((r) => ({
    ...r,
    path: getPathWithoutParam(r.path),
  }))

  const findedActiveTab = withoutParamsRoutes.find((r) =>
    activePath[0].includes(r.path),
  )

  return findedActiveTab
}

export const findSubMenuActiveTab = (subMenuRoutes, activePath) => {
  const parsedActivePath = removeDynamicParam(activePath)
  const activeSubMenuRoute =
    subMenuRoutes.find(
      (route) => parsedActivePath === removeDynamicParam(route.path),
    ) || null

  return activeSubMenuRoute
}

export const removeDynamicParam = (str) => {
  const parts = str.split('/')
  const newStr = parts.slice(0, 4).join('/')
  return newStr
}
