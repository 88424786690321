import axios from './axios'
import AbstractApi from './AbstractApi'
import { ROLES_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class Role extends AbstractApi {
  constructor() {
    super(ROLES_URL)
  }

  findAllResources = ({ token, params }) =>
    axios.get(
      `${process.env.REACT_APP_API}${ROLES_URL}/resources`,
      getHeaders(token, params),
    )
}

export default new Role()
