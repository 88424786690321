import axios from './axios'
import AbstractApi from './AbstractApi'
import { REGION_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class Region extends AbstractApi {
  constructor() {
    super(REGION_URL)
  }

  change = ({ token, _id }) =>
    axios.post(`${this.url}/change/${_id}`, {}, getHeaders(token))

  message = ({ token, obj, _id }) =>
    axios.post(`${this.url}/message/${_id}`, obj, getHeaders(token))

  getOrganizations = ({ token, _id }) =>
    axios.get(`${this.url}/by-organization/${_id}`, getHeaders(token))

  getCurrentRegion = ({ token }) =>
    axios.get(`${this.url}/current`, getHeaders(token))
}

export default new Region()
