import axios from './axios'
import AbstractApi from './AbstractApi'
import { PUBLIC_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class Public extends AbstractApi {
  constructor() {
    super(PUBLIC_URL)
  }

  findAllPickups = ({ params }) =>
    axios.get(`${this.url}/pickup-dates`, getHeaders(null, params))

  create = ({ obj }) => axios.post(`${this.url}/create`, obj)
}

export default new Public()
