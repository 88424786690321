export const LOGIN_URL = '/login'
export const USER_URL = '/users'
export const TRUCK_URL = '/trucks'
export const COLLECTION_LOCATION_URL = '/collection-locations'
export const COLLECTION_LOCATION_HISTORY_URL = '/collection-locations-pickups'
export const STORE_URL = '/stores'
export const DONATION_URL = '/donations'
export const DONATION_ITEM_URL = '/donation-items'
export const DONOR_URL = '/donors'
export const DONATION_REPORT_URL = '/donations/reports'
export const ORGANIZATION_URL = '/organizations'
export const HUB_URL = '/hubs'
export const SCALAR_URL = '/scalars'
export const DRIVER_URL = '/drivers'
export const ROLES_URL = '/roles'
export const COUNTRY_URL = '/countries'
export const POSTAL_CODE_URL = '/postal-codes'
export const UNLOCK_URL = '/unlock'
export const CALENDAR = '/calendar'
export const IMPORT_DATA = '/import-data'
export const ROUTES_URL = '/routes'
export const PUBLIC_URL = '/public'
export const HOLIDAY_URL = '/holidays'
export const ZONE_URL = '/zones'
export const REGION_URL = '/regions'
export const DEDICATED_DELIVERY_PLAN_URL = '/ddp'
export const OSD_URL = '/osd'
export const PRICING_URL = '/pricing'
export const INVENTORY_URL = '/inventory'
