import { useMemo } from 'react'
import { cities, regions } from 'canada'

/**
 * cities => Return all cities
 * provinces => Return all provinces
 * territories => Returns the 3 Canadian territories.
 * regions => This is both provinces and territories.
 * citiesInProvince => Returns cities grouped by region
 */

export const useCanada = () => {
  const memorizeCities = useMemo(
    () =>
      cities.map((element) => ({
        city: element[0],
        province: element[1],
      })),
    [],
  )

  const memorizeCitiesInProvince = useMemo(() => {
    const citiesInProvince = {}

    Object.keys(regions).forEach((element) => (citiesInProvince[element] = []))

    memorizeCities.forEach(({ city, province }) =>
      citiesInProvince[province].push({ city, province }),
    )

    return citiesInProvince
  }, [memorizeCities])

  const allProvinces = () => {
    const cloneRegions = { ...regions }
    cloneRegions['NT'] = cloneRegions['NT'].toUpperCase()
    cloneRegions['NU'] = cloneRegions['NU'].toUpperCase()
    cloneRegions['YT'] = cloneRegions['YT'].toUpperCase()
    return cloneRegions
  }

  return {
    cities: memorizeCities,
    provinces: allProvinces(),
    citiesInProvince: memorizeCitiesInProvince,
  }
}
