import axios from './axios'
import AbstractApi from './AbstractApi'
import { COUNTRY_URL } from './apiConstants'

let api_url = 'http://dev.dealportal.ca:3600/api/countries'

class Country extends AbstractApi {
  constructor() {
    super(COUNTRY_URL)
  }

  findCountries = () => axios.get(`${api_url}`)

  findProvince = ({ country }) => axios.get(`${api_url}/states/${country}`)

  findCities = ({ state }) => axios.get(`${api_url}/states/cities/${state}`)
}

export default new Country()
