import { useSelector } from 'react-redux'

import logoWhite from '../assets/img/LogoKidneyC-dark.png'
import logoBlack from '../assets/img/LogoKidneyC.png'

import { selectIsDarkTheme } from '../redux/global/selectors'

const LoadingPage = () => {
  const isDarkTheme = useSelector(selectIsDarkTheme)

  const logo = isDarkTheme ? logoWhite : logoBlack

  // In this page we use inline styles because if we use some classes or import any css file, it will not be displayed faster enough to show in pages loading
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: isDarkTheme ? '#000000' : '#f0f2f5',
      }}
    >
      <img alt='Img loading splash' height='75' src={logo} />
    </div>
  )
}

export default LoadingPage
