import { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { io } from 'socket.io-client'

import { selectToken } from '../../redux/session/selectors'

const SocketContext = createContext({})

export const useSocket = () => useContext(SocketContext)

const SocketContextProvider = ({ children }) => {
  const sessionToken = useSelector(selectToken)

  const [socket, setSocket] = useState()

  useEffect(() => {
    if (!sessionToken) return

    const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
      extraHeaders: {
        Authorization: sessionToken,
      },
      reconnection: false,
    })

    const tryReconnect = () => {
      console.log('Reconnecting socket in 5 sec...')
      setTimeout(() => {
        newSocket.io.open((err) => {
          if (err) {
            console.log('Reconnect failed socket')
            return tryReconnect()
          }
          console.log('Restored connection socket')
        })
      }, 5000)
    }

    newSocket.on('connect', () => {
      console.log('Socket connected')
      setSocket(newSocket)
    })

    newSocket.on('disconnect', tryReconnect)

    return () => {
      newSocket.close()
    }
  }, [sessionToken])

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  )
}

export default SocketContextProvider
