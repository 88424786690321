import {
  SET_LOCALE,
  SET_ORGANIZATION,
  SET_REGION,
  SET_REFRESH_DATA,
  SET_THEME,
} from './constants'

export const setLocale = (locale) => ({
  type: SET_LOCALE,
  locale,
})

export const setOrganization = (organization) => ({
  type: SET_ORGANIZATION,
  organization,
})

export const setRefreshData = (isRefresh) => ({
  type: SET_REFRESH_DATA,
  isRefresh,
})

export const setRegion = (region) => ({
  type: SET_REGION,
  region,
})

export const setTheme = (theme) => ({
  type: SET_THEME,
  theme,
})
