import axios from './axios'
import AbstractApi from './AbstractApi'
import { DONATION_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class Donation extends AbstractApi {
  constructor() {
    super(DONATION_URL)
  }

  reportRoutes = ({ token, params }) =>
    axios.get(
      `${process.env.REACT_APP_API}${DONATION_URL}/report`,
      getHeaders(token, params),
    )

  addressValidation = ({ token, params }) =>
    axios.get(
      `${process.env.REACT_APP_API}${DONATION_URL}/address-validation`,
      getHeaders(token, params),
    )

  assignAddOn = ({ token, obj, donationId }) =>
    axios.post(
      `${process.env.REACT_APP_API}${DONATION_URL}/assign-addon/${donationId}`,
      obj,
      getHeaders(token),
    )

  badAddress = ({ token, _id }) =>
    axios.get(`${this.url}/bad-address/${_id}`, getHeaders(token))

  emergencyChange = ({ token, obj }) =>
    axios.post(`${this.url}/emergency-change`, obj, getHeaders(token))

  tbd = ({ token }) => axios.get(`${this.url}/tbd`, getHeaders(token))

  unassignDonation = ({ token, obj }) =>
    axios.post(`${this.url}/unassign`, obj, getHeaders(token))

  getAddOns = ({ token }) => axios.get(`${this.url}/addons`, getHeaders(token))

  addOnsList = ({ token, obj }) =>
    axios.get(`${this.url}/addons-list`, getHeaders(token, obj))

  excludeStops = ({ token }) =>
    axios.get(`${this.url}/excluded-stops`, getHeaders(token))

  getDonationDuplicated = ({ token, params }) =>
    axios.get(`${this.url}/route-manifest/alerts`, getHeaders(token, params))

  getDialers = ({ token, params }) =>
    axios.get(`${this.url}/dialers`, getHeaders(token, params))

  removeDialers = ({ token, params, _id }) =>
    axios.delete(`${this.url}/remove-dialers/${_id}`, getHeaders(token, params))

  pickupUpdate = ({ token, obj, _id }) =>
    axios.put(
      `${process.env.REACT_APP_API}${DONATION_URL}/pickup/${_id}`,
      obj,
      getHeaders(token),
    )
}

export default new Donation()
