import axios from './axios'
import AbstractApi from './AbstractApi'
import { ROUTES_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class Route extends AbstractApi {
  constructor() {
    super(ROUTES_URL)
  }

  generateGPSFile = ({ token, obj }) =>
    axios.post(
      `${process.env.REACT_APP_API}${ROUTES_URL}/manifest/generate`,
      obj,
      getHeaders(token),
    )

  map = ({ token, _id }) =>
    axios.get(
      `${process.env.REACT_APP_API}${ROUTES_URL}/map/${_id}`,
      getHeaders(token),
    )

  toggleLock = ({ token, _id }) =>
    axios.post(`${this.url}/toggleLock/${_id}`, {}, getHeaders(token))

  rebuild = ({ token, date }) =>
    axios.post(`${this.url}/rebuild/${date}`, {}, getHeaders(token))

  collectionLocationReport = ({ token, params }) =>
    axios.get(
      `${this.url}/reports/collection-locations`,
      getHeaders(token, params),
    )

  unManifestedRoutes = ({ token }) =>
    axios.get(`${this.url}/unmanifested`, getHeaders(token))

  dropOff = ({ token, obj, _id }) =>
    axios.put(`${this.url}/dropOff/${_id}`, obj, getHeaders(token))

  assignDonations = ({ token, obj, _id }) =>
    axios.put(
      `${process.env.REACT_APP_API}${ROUTES_URL}/assign-donations/${_id}`,
      obj,
      getHeaders(token),
    )

  routesStats = ({ token, obj, _id }) =>
    axios.put(
      `${process.env.REACT_APP_API}/routes-stats/${_id}`,
      obj,
      getHeaders(token),
    )

  findAllRoutesStats = ({ token, _id }) =>
    axios.get(
      `${process.env.REACT_APP_API}/routes-stats/${_id}`,
      getHeaders(token),
    )

  dailyActivity = ({ token, params }) =>
    axios.get(`${this.url}/reports/daily-activity`, getHeaders(token, params))

  inventoryReport = ({ token, params }) =>
    axios.get(`${this.url}/reports/inventory-report`, getHeaders(token, params))
  statsReport = ({ token, params }) =>
    axios.get(`${this.url}/reports/stats-report`, getHeaders(token, params))
  productionReport = ({ token, params }) =>
    axios.get(
      `${this.url}/reports/production-report`,
      getHeaders(token, params),
    )
  findAllProductionData = ({ token, params }) =>
    axios.get(`${this.url}/production-data`, getHeaders(token, params))
}

export default new Route()
