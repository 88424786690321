import { useState } from 'react'
import { Button, message, Steps, Form } from 'antd'

import DoneStep from './components/DoneStep'
import LocationAndDateStep from './components/LocationAndDateStep'
import ContactInformationStep from './components/ContactInformationStep'

import api from '../../api'
import { useSteps } from '../../utils/hooks/useSteps'
import { useGet, useMutation } from '../../utils/functions/hooks'

const { Step } = Steps
const { useForm } = Form

const PickupPage = () => {
  const [form] = useForm()
  const [stepValues, setStepValues] = useState({})

  const {
    data,
    loading: pickupsLoading,
    refetch: pickupsRefetch,
  } = useGet(api.publicRoute.findAllPickups, {}, { cancelFirstEffect: true })

  const onCompleted = () => {
    message.success('Processing complete!')
    setStepValues({})
    resetStep()
  }

  const [pickupMutation, { loading }] = useMutation(api.publicRoute.create, {
    onCompleted,
  })

  const handleLocationAndDateStepForm = async () => {
    const { pickupDateId, ..._values } = await form.getFieldsValue()

    if (!pickupDateId)
      return message.info('You must select at least one pickup!')

    setStepValues((currentValues) => ({
      ...currentValues,
      step1: {
        pickupDateId,
        code: process.env.REACT_APP_ORGANIZATION_CODE,
        // pickup: data.find((item) => item._id === pickupDateId),
        ..._values,
      },
    }))

    goNext()
  }

  const handleContactInformationStepForm = async () => {
    const values = await form.getFieldsValue()

    setStepValues((currentValues) => ({
      ...currentValues,
      step2: { ...values },
    }))

    goNext()
  }

  const handleDoneStepForm = async () => {
    pickupMutation({
      obj: {
        ...stepValues.step1,
        ...stepValues.step2,
        address: {
          ...stepValues.step2.address,
          postalCode: stepValues?.step1.postalCode,
        },
        code: process.env.REACT_APP_ORGANIZATION_CODE,
      },
    })
  }

  const steps = [
    {
      title: 'Location and Date',
      content: (
        <LocationAndDateStep
          form={form}
          pickups={data || []}
          loading={pickupsLoading}
          setValues={setStepValues}
          onSearch={pickupsRefetch}
        />
      ),
      onDone: handleLocationAndDateStepForm,
    },
    {
      title: 'Contact Information',
      content: <ContactInformationStep values={stepValues} />,
      onDone: handleContactInformationStepForm,
    },
    {
      title: 'Done',
      content: <DoneStep values={stepValues} />,
      onDone: handleDoneStepForm,
    },
  ]

  const { currentStep, currentStepObj, isLastStep, goBack, goNext, resetStep } =
    useSteps(steps)

  const onFinish = () => currentStepObj.onDone()

  return (
    <Form form={form} onFinish={onFinish}>
      <div>
        <div style={{ maxWidth: '600px', margin: '4em auto' }}>
          <div>
            <Steps current={currentStep}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
          <div className='mt-5'>{steps[currentStep].content}</div>

          <div className='mt-4 d-flex justify-content-end'>
            {currentStep > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={goBack}>
                Previous
              </Button>
            )}
            <Button type='primary' htmlType='submit' loading={loading}>
              {isLastStep ? 'Done' : 'Next'}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default PickupPage
