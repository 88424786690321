import AbstractApi from './AbstractApi'
import { DONATION_ITEM_URL } from './apiConstants'

class DonationItems extends AbstractApi {
  constructor() {
    super(DONATION_ITEM_URL)
  }
}

export default new DonationItems()
