import PopoverForm from '../../dialogs/PopoverForm'
import { ToolOutlined } from '@ant-design/icons'

import ThemeSwitch from '../../util/ThemeSwitch'
import LanguageSelector from '../../../intl/LanguageSelector'

import './OptionsPopover.css'

const OptionsPopover = ({ block }) => {
  return (
    <PopoverForm
      type='text'
      icon={<ToolOutlined />}
      width={90}
      placement='bottom'
      block={block}
    >
      <>
        <div className='options-popover__content'>
          <div className='options-popover__theme-switch'>
            <ThemeSwitch />
          </div>
          <LanguageSelector />
        </div>
      </>
    </PopoverForm>
  )
}

export default OptionsPopover
