import axios from './axios'
import AbstractApi from './AbstractApi'
import { LOGIN_URL, USER_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class User extends AbstractApi {
  constructor() {
    super(USER_URL)
  }

  updateProfile = ({ token, obj }) =>
    axios.patch(`${process.env.REACT_APP_API}/me`, obj, getHeaders(token))

  changePassword = ({ token, obj }) =>
    axios.patch(
      `${process.env.REACT_APP_API}/update-password`,
      obj,
      getHeaders(token),
    )

  login = ({ obj }) =>
    axios.post(`${process.env.REACT_APP_API}${LOGIN_URL}`, obj)
}

export default new User()
