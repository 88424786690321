import { Layout, theme } from 'antd'

import ThemeSwitch from '../util/ThemeSwitch'
import LanguageSelector from '../../intl/LanguageSelector'

const { Header } = Layout

const AuthHeader = () => {
  const { token } = theme.useToken()

  return (
    <Header style={{ background: token.colorBgContainer }}>
      <div className='auth-header'>
        <div />
        <div className='auth-header-menu'>
          <div>
            <ThemeSwitch />
            <span className='mx-3'>
              <LanguageSelector />
            </span>
          </div>
        </div>
      </div>
    </Header>
  )
}

export default AuthHeader
