import dayjs from 'dayjs'

export const format = 'MM/DD/YYYY'

export const getDate = (value, format) => dayjs(value, format)
export const getDateUtc = (value, format) => dayjs.utc(value, format)
export const getDateToday = (val) => (val ? dayjs(val) : dayjs())
export const getDateTodayUtc = () => dayjs().utc()

export const formatDate = (value, customFormat, utc = true) => {
  const date = utc ? getDateUtc(value) : getDate(value)
  return value && date.isValid() ? date.format(customFormat) : ''
}

export const formatDateToday = (customFormat = format, utc = true) => {
  const date = utc ? getDateUtc() : getDate()
  return date.format(customFormat)
}

export const getDateEndOf = ({ val, type }) => dayjs(val || '').endOf(type)

export const getDateInterval = ({
  startDate = dayjs(),
  interval,
  typeInterval,
}) => ({
  startDate: startDate.subtract(1, 'day'),
  endDate: dayjs().add(interval, typeInterval),
})

export const getMonthAndYear = (value) => {
  const date = getDateUtc(value)

  return {
    month: date.format('MM'),
    year: date.format('YYYY'),
  }
}

export const findByValueDate = ({ data, currentDate, date }) =>
  data?.find(
    (elem) =>
      formatDate(elem[date], 'YYYY-MM-DD') ===
      formatDate(currentDate, 'YYYY-MM-DD'),
  )
