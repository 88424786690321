import AbstractApi from './AbstractApi'
import { HUB_URL } from './apiConstants'

class Hub extends AbstractApi {
  constructor() {
    super(HUB_URL)
  }
}

export default new Hub()
