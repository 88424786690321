import Icon from '@ant-design/icons'

import { ReactComponent as Sun } from './sun.svg'
import { ReactComponent as Moon } from './moon.svg'
import { ReactComponent as Truck } from './truck.svg'

import { ReactComponent as TruckCompleted } from './truckCompleted.svg'
import { ReactComponent as TruckCreated } from './truckCreated.svg'
import { ReactComponent as TruckInProgress } from './truckInProgress.svg'
import { ReactComponent as TruckManifested } from './truckManifested.svg'
import { ReactComponent as TruckPendingManifest } from './truckPendingManifest.svg'
import { ReactComponent as TruckUnManifested } from './truckUnManifested.svg'
import { ReactComponent as TruckWaitingRemanifest } from './truckWaitingRemanifest.svg'
import { ReactComponent as Alert } from './alert.svg'
import { ReactComponent as Delivered } from './delivered.svg'

const createIcon = (iconSvg) => (props) =>
  <Icon component={iconSvg} {...props} />

export const SunIcon = createIcon(Sun)
export const MoonIcon = createIcon(Moon)
export const TruckIcon = createIcon(Truck)

export const TruckCompletedIcon = createIcon(TruckCompleted)
export const TruckCreatedIcon = createIcon(TruckCreated)
export const TruckInProgressIcon = createIcon(TruckInProgress)
export const TruckManifestedIcon = createIcon(TruckManifested)
export const TruckPendingManifestIcon = createIcon(TruckPendingManifest)
export const TruckReManifestedIcon = createIcon(TruckUnManifested)
export const TruckWaitingRemanifestIcon = createIcon(TruckWaitingRemanifest)
export const AlertIcon = createIcon(Alert)
export const DeliveredIcon = createIcon(Delivered)
