import AbstractApi from './AbstractApi'
import { STORE_URL } from './apiConstants'

class Store extends AbstractApi {
  constructor() {
    super(STORE_URL)
  }
}

export default new Store()
