import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { App, ConfigProvider, theme } from 'antd'

import antdEnLocale from 'antd/lib/locale/en_US'
import antdFRLocale from 'antd/lib/locale/fr_FR'
import { selectIsDarkTheme } from '../../redux/global/selectors'
import { useEffect } from 'react'

const antdLocales = {
  en: antdEnLocale,
  es: antdFRLocale,
}

const AntdWrapper = ({ children, locale }) => {
  const { token } = theme.useToken()
  const { formatMessage } = useIntl()
  const isDarkTheme = useSelector(selectIsDarkTheme)

  const validateMessages = {
    required: formatMessage({ id: 'requiredField' }, { fieldName: '${label}' }),
    types: {
      email: formatMessage({ id: 'formatEmail' }, { fieldName: '${label}' }),
    },
  }

  useEffect(() => {
    document.body.className = `theme--${isDarkTheme ? 'dark' : 'light'}`
  }, [isDarkTheme])

  return (
    <ConfigProvider
      locale={antdLocales[locale]}
      form={{ validateMessages }}
      theme={{
        algorithm: isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorPrimary: '#e8296d',
          fontFamily: `'Open Sans', sans-serif, ${token.fontFamily}`,
          borderRadius: 4,
          colorTextDisabled: '#000',
        },
      }}
    >
      <App>{children}</App>
    </ConfigProvider>
  )
}

export default AntdWrapper
