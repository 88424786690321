import axios from './axios'
import AbstractApi from './AbstractApi'
import { DONATION_REPORT_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class DonationReport extends AbstractApi {
  constructor() {
    super(DONATION_REPORT_URL)
  }

  donorForecast = ({ token, params }) =>
    axios.get(`${this.url}/donor-forecast`, getHeaders(token, params))

  postalCodeReport = ({ token, params }) =>
    axios.get(`${this.url}/postal-code-forecast`, getHeaders(token, params))

  pickupResultsDetails = ({ token, params }) =>
    axios.get(`${this.url}/pickup-results`, getHeaders(token, params))

  donationInformation = ({ token, params }) =>
    axios.get(`${this.url}/donation-information`, getHeaders(token, params))

  collectionLocationSummary = ({ token, params }) =>
    axios.get(
      `${this.url}/collection-location-summary`,
      getHeaders(token, params),
    )

  solicitationsCalendar = ({ token, params }) =>
    axios.get(`${this.url}/solicitations-calendar`, getHeaders(token, params))
}

export default new DonationReport()
