import { Provider } from 'react-redux'
import store, { persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import Main from './navigation/Main'
import Auth from './navigation/Auth'
import NotFound from './views/NotFound'
import AuthLayout from './layouts/AuthLayout'
import MainLayout from './layouts/MainLayout'
import PickupPage from './views/Pickup/PickupPage'

import { loginPath } from './routes'
import IntlWrapper from './intl/components/IntlWrapper'
import SocketContextProvider from './providers/SocketProvider/SocketProvider'

import 'antd/dist/reset.css'
import './assets/css/app.css'
import 'bootstrap-4-grid/css/grid.min.css'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading='Loading' persistor={persistor}>
        <IntlWrapper>
          <SocketContextProvider>
            <BrowserRouter>
              <Switch>
                <Route
                  exact
                  path='/'
                  component={() => <Redirect to={loginPath} />}
                />
                <Auth path='/auth' component={AuthLayout} />
                <Main path='/main' component={MainLayout} />
                <Route path='/pickup' component={() => <PickupPage />} />
                <Route component={NotFound} />
              </Switch>
            </BrowserRouter>
          </SocketContextProvider>
        </IntlWrapper>
      </PersistGate>
    </Provider>
  )
}

export default App
