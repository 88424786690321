import { useState, cloneElement } from 'react'
import { Popover, Button } from 'antd'

const PopoverForm = ({
  children,
  type,
  title,
  icon,
  block,
  className,
  placement,
  width,
}) => {
  const [visible, setVisible] = useState(false)
  const closeDialog = () => setVisible(false)

  const newChildren = cloneElement(children || <></>, { closeDialog })

  return (
    <Popover
      content={newChildren}
      open={visible}
      onOpenChange={(visible) => setVisible(visible)}
      trigger='click'
      placement={placement}
      overlayStyle={
        width && {
          width,
        }
      }
    >
      <Button icon={icon} type={type} block={block} className={className}>
        {title}
      </Button>
    </Popover>
  )
}

PopoverForm.defaultProps = {
  children: <></>,
  type: 'text',
  placement: 'bottom',
  width: 190,
}

export default PopoverForm
