import AbstractApi from './AbstractApi'
import { INVENTORY_URL } from './apiConstants'

class Inventory extends AbstractApi {
  constructor() {
    super(INVENTORY_URL)
  }
}

export default new Inventory()
