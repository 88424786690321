import AbstractApi from './AbstractApi'
import { OSD_URL } from './apiConstants'

class Osd extends AbstractApi {
  constructor() {
    super(OSD_URL)
  }
}

export default new Osd()
