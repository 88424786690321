import { Switch } from 'antd'
import { MoonIcon, SunIcon } from '../../assets/icons'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsDarkTheme } from '../../redux/global/selectors'
import { AvailableThemes } from '../../utils/constants/themeConstant'
import { setTheme } from '../../redux/global/actions'

const ThemeSwitch = () => {
  const isDarkTheme = useSelector(selectIsDarkTheme)

  const dispatch = useDispatch()

  const handleChangeTheme = (isDarkMode) => {
    dispatch(
      setTheme(isDarkMode ? AvailableThemes.dark : AvailableThemes.light),
    )
  }

  return (
    <Switch
      checked={isDarkTheme}
      onChange={handleChangeTheme}
      checkedChildren={<MoonIcon />}
      unCheckedChildren={<SunIcon />}
    />
  )
}

export default ThemeSwitch
