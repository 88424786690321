import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { message, Select } from 'antd'

import api from '../../../api'
import { useCanada } from '../../../utils/hooks/useCanada'
import { useMutation } from '../../../utils/functions/hooks'
import { setRefreshData, setRegion } from '../../../redux/global/actions'
import { selectToken, selectUser } from '../../../redux/session/selectors'
import {
  selectOrganizationId,
  selectRegion,
} from '../../../redux/global/selectors'

import './RegionSelect.css'

const { Option } = Select

const RegionSelect = ({
  token,
  region,
  onSetRegion,
  user,
  organizationId,
  onRefresh,
}) => {
  const { formatMessage } = useIntl()
  const { provinces } = useCanada()
  const regions = user?.regionsOfOrganization[organizationId] || []

  const onCompleted = () => {
    message.success(formatMessage({ id: 'changeRegionSuccess' }))
    onRefresh()
  }

  const [regionMutation, { loading: regionLoading }] = useMutation(
    api.region.change,
    { onCompleted },
  )

  const onChange = (value) => {
    const data = regions.find((d) => d._id === value)
    const abbr = Object.entries(provinces).find(
      (e) => e[1] === data.province,
    )[0]
    onSetRegion({ ...data, abbr })
    regionMutation({ token, _id: value })
  }

  return (
    <Select
      className='region-select__select'
      placeholder={formatMessage({ id: 'labelFilterOrganization' })}
      loading={regionLoading}
      onChange={onChange}
      value={region._id}
    >
      {user?.regionsOfOrganization &&
        user?.regionsOfOrganization[organizationId]?.map((item) => (
          <Option key={item._id} value={item._id}>
            {item.name}
          </Option>
        ))}
    </Select>
  )
}

const mapStateToProps = (state) => ({
  token: selectToken(state),
  region: selectRegion(state),
  organizationId: selectOrganizationId(state),
  user: selectUser(state),
})

const mapDispatchToProps = (dispatch) => ({
  onSetRegion: (region) => dispatch(setRegion(region)),
  onRefresh: () => dispatch(setRefreshData(true)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegionSelect)
