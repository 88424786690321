import { useState } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import { Link, Route, Switch } from 'react-router-dom'
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Layout, Menu, Popover, Space, theme } from 'antd'

import NotFound from '../views/NotFound'
import NameAvatar from '../components/avatar/NameAvatar'
import DrawerForm from '../components/dialogs/DrawerForm'
import RegionSelect from '../components/controls/RegionSelect/RegionSelect'
import OptionsPopover from '../components/popover/optionsPopover/OptionsPopover'

import { logout } from '../redux/session/actions'
import { mainDefaultPath, mainRoutes } from '../routes'
import {
  findActiveTab,
  findSubMenuActiveTab,
  generateLinks,
  generateRoutes,
  useActivePaths,
  useActivePathsToBreadcrumb,
} from '../utils/functions/routeUtils'
import { selectUser, selectUserPermissions } from '../redux/session/selectors'

const { Sider, Content } = Layout

const allAccess = ['tenant', 'all']

const MainLayout = ({ user, permissions, handleLogout }) => {
  const { formatMessage } = useIntl()
  const [collapsed, setCollapsed] = useState(false)

  const accessRoutes = user.role && mainRoutes ? mainRoutes : []
  const activePaths = useActivePaths()
  const activePathsBreadcrumb = useActivePathsToBreadcrumb()
  const activeTab = findActiveTab(accessRoutes, activePaths)
  const activeSubMenuTab = findSubMenuActiveTab(
    activeTab?.subMenuRoutes || [],
    activePaths[0],
  )
  const _permissions = permissions ? [...permissions] : []
  const isFullPermissions = !!_permissions.find((item) =>
    allAccess.includes(item.resource),
  )
  const resources = [
    'changePassword',
    'editProfile',
    ...new Set(_permissions.map((item) => item.resource)),
  ]

  const MainMenu = ({ closeDialog = () => {} }) => (
    <>
      <Link to={mainDefaultPath}>
        <div className='main-logo-container'>
          <div className='main-logo__item' />
        </div>
      </Link>
      <Menu
        mode='vertical'
        defaultSelectedKeys={activePaths}
        onClick={closeDialog}
      >
        {generateLinks(
          accessRoutes,
          true,
          formatMessage,
          isFullPermissions,
          resources,
        )}
      </Menu>
      <div className='d-flex justify-content-center mt-4'>
        <OptionsPopover block />
      </div>
    </>
  )

  const UserMenu = (
    <>
      <p className='text-center'>
        {user.firstName} {user.lastName}
      </p>
      <Button
        type='text'
        block
        onClick={handleLogout}
        icon={<LogoutOutlined />}
      >
        {formatMessage({ id: 'logout' })}
      </Button>
    </>
  )

  const itemsBreadcrumb = [
    ...activePathsBreadcrumb
      .filter((path) => path)
      .map((path) => ({
        title: formatMessage({
          id: path.replace(/-([a-z])/g, (g) => g[1].toUpperCase()),
        }),
      })),
  ]

  const { token } = theme.useToken()

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(isCollapsed) => setCollapsed(isCollapsed)}
        className='d-none d-md-block'
        style={{ backgroundColor: token.colorBgContainer }}
      >
        <MainMenu />
      </Sider>
      <Layout>
        <Content>
          <div className='page-title layout-header flex-wrap'>
            <div>
              {activeTab && (
                <>
                  <h1 className='mb-0'>
                    {activeTab.subMenuRoutes ? (
                      <>
                        {activeSubMenuTab.icon}{' '}
                        {activeSubMenuTab.name &&
                          formatMessage({ id: activeSubMenuTab?.name })}
                      </>
                    ) : (
                      <>
                        {activeTab.icon} {formatMessage({ id: activeTab.name })}
                      </>
                    )}
                  </h1>
                  <Breadcrumb
                    className='main-layout__breadcrumb'
                    items={itemsBreadcrumb}
                  />
                </>
              )}
            </div>
            <Space>
              <RegionSelect />
              <Popover
                content={UserMenu}
                placement='leftTop'
                trigger={['click', 'hover']}
              >
                <div className='d-flex align-items-center'>
                  <NameAvatar name={user.firstName} />
                </div>
              </Popover>
              <span className='d-inline d-md-none'>
                <DrawerForm
                  drawerTitle='Menu'
                  icon={<MenuOutlined />}
                  bodyStyle={{ padding: '0' }}
                >
                  <MainMenu />
                </DrawerForm>
              </span>
            </Space>
          </div>
          <div className='container-fluid mb-4 layout-main'>
            <Switch>
              {generateRoutes(accessRoutes, isFullPermissions, resources)}
              <Route component={NotFound} />
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  user: selectUser(state),
  permissions: selectUserPermissions(state),
})

const mapDispatchToProps = (dispatch) => ({
  handleLogout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
