import axios from 'axios'

import store from '../redux/store'
import { logout } from '../redux/session/actions'
import { STATUS_CODES } from './../utils/constants/httpStatusCodesConstants'

const axiosInstance = axios.create({})

const onFulfilled = (next) => Promise.resolve(next)

const onRejected = (error) => {
  if (error.response && error.response.status === STATUS_CODES.UNAUTHORIZED)
    store.dispatch(logout())

  return Promise.reject(error)
}

axiosInstance.interceptors.response.use(onFulfilled, onRejected)

export default axiosInstance
