import axios from './axios'
import AbstractApi from './AbstractApi'
import { POSTAL_CODE_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class PostalCode extends AbstractApi {
  constructor() {
    super(POSTAL_CODE_URL)
  }

  updateActive = ({ token, obj, code }) =>
    axios.put(`${this.url}/many/${code}`, obj, getHeaders(token))
}

export default new PostalCode()
