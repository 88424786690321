import { LOGIN, LOGOUT, EDIT_PROFILE } from './constants'

export const login = (user, token, permissions) => ({
  type: LOGIN,
  user,
  token,
  permissions,
})

export const logout = () => ({ type: LOGOUT })

export const editProfile = (user) => ({ type: EDIT_PROFILE, user })
