import AbstractApi from './AbstractApi'
import { COLLECTION_LOCATION_HISTORY_URL } from './apiConstants'

class CollectionLocationHistory extends AbstractApi {
  constructor() {
    super(COLLECTION_LOCATION_HISTORY_URL)
  }
}

export default new CollectionLocationHistory()
