import AbstractApi from './AbstractApi'
import { TRUCK_URL } from './apiConstants'

class Truck extends AbstractApi {
  constructor() {
    super(TRUCK_URL)
  }
}

export default new Truck()
