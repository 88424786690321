import axios from './axios'
import AbstractApi from './AbstractApi'
import { DONOR_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class Donor extends AbstractApi {
  constructor() {
    super(DONOR_URL)
  }

  updateAddress = ({ token, obj, _id }) =>
    axios.put(
      `${process.env.REACT_APP_API}${DONOR_URL}/update-address/${_id}`,
      obj,
      getHeaders(token),
    )

  validateAddressOne = ({ token, obj }) =>
    axios.post(
      `${process.env.REACT_APP_API}${DONOR_URL}/validate-one`,
      obj,
      getHeaders(token),
    )

  validateAddressAll = ({ token, obj }) =>
    axios.post(
      `${process.env.REACT_APP_API}${DONOR_URL}/validate-all`,
      obj,
      getHeaders(token),
    )

  findAllDonorAddress = ({ token, donorId }) =>
    axios.get(
      `${process.env.REACT_APP_API}/donor-address/donor/${donorId}`,
      getHeaders(token),
    )

  addDonorAddress = ({ token, obj }) =>
    axios.post(
      `${process.env.REACT_APP_API}/donor-address`,
      obj,
      getHeaders(token),
    )

  deleteAddress = ({ token, _id }) =>
    axios.delete(
      `${process.env.REACT_APP_API}/donor-address/${_id}`,
      getHeaders(token),
    )

  updateDonorAddress = ({ token, obj, _id }) =>
    axios.put(
      `${process.env.REACT_APP_API}/donor-address/${_id}`,
      obj,
      getHeaders(token),
    )
}

export default new Donor()
