import axios from './axios'
import AbstractApi from './AbstractApi'
import { ORGANIZATION_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class Organization extends AbstractApi {
  constructor() {
    super(ORGANIZATION_URL)
  }

  regenerateCode = ({ token, organizationId }) =>
    axios.post(
      `${this.url}/regenerate/${organizationId}`,
      {},
      getHeaders(token),
    )

  change = ({ token, _id }) =>
    axios.post(`${this.url}/change/${_id}`, {}, getHeaders(token))
}

export default new Organization()
