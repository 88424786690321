import AbstractApi from './AbstractApi'
import { HOLIDAY_URL } from './apiConstants'

class Holiday extends AbstractApi {
  constructor() {
    super(HOLIDAY_URL)
  }
}

export default new Holiday()
