import axios from './axios'
import AbstractApi from './AbstractApi'
import { IMPORT_DATA } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class DialerImport extends AbstractApi {
  constructor() {
    super(IMPORT_DATA)
  }

  dialerCSVUpload = ({ token, obj }) =>
    axios.post(
      `${process.env.REACT_APP_API}${IMPORT_DATA}/dialer-csv-upload`,
      obj,
      getHeaders(token),
    )
}

export default new DialerImport()
