import { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import enLanguage from '../languages/en.json'
import esLanguage from '../languages/es.json'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjsUtc from 'dayjs/plugin/utc'
import AntdWrapper from './AntdWrapper'
import { selectLocale } from '../../redux/global/selectors'
import 'dayjs/locale/es'

dayjs.extend(localizedFormat)
dayjs.extend(dayjsUtc)

const localeMessages = {
  en: enLanguage,
  es: esLanguage,
}

const IntlWrapper = ({ children }) => {
  const locale = useSelector(selectLocale)

  useEffect(() => {
    if (locale) {
      dayjs.locale(locale)
    }
  }, [locale])

  return (
    <IntlProvider locale={locale} messages={localeMessages[locale]}>
      <AntdWrapper locale={locale}>{children}</AntdWrapper>
    </IntlProvider>
  )
}

export default IntlWrapper
