import AbstractApi from './AbstractApi'
import { DRIVER_URL } from './apiConstants'

class Driver extends AbstractApi {
  constructor() {
    super(DRIVER_URL)
  }
}

export default new Driver()
