import { produce } from 'immer'

import { EDIT_PROFILE, LOGIN, LOGOUT } from './constants'

const initialState = {}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGIN:
        draft.user = action.user
        draft.token = action.token
        draft.permissions = action.permissions
        break
      case LOGOUT:
        return initialState
      case EDIT_PROFILE:
        draft.user = action.user
        break
      default:
        break
    }
  })

export default reducer
