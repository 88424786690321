import { AvailableThemes } from '../../utils/constants/themeConstant'

const reducerKey = 'global'

export const selectLocale = (state) => state[reducerKey].locale
export const selectOrganization = (state) => state[reducerKey].organization
export const selectRegion = (state) => state[reducerKey].region
export const selectOrganizationId = (state) =>
  state[reducerKey].organization?._id
export const selectRegionId = (state) => state[reducerKey].region?._id
export const selectIsRefreshData = (state) => state[reducerKey].isRefresh

export const selectProvince = (state) => state[reducerKey].region?.province
export const selectAbbrProvince = (state) => state[reducerKey].region?.abbr

export const selectIsDarkTheme = (state) =>
  state[reducerKey].theme === AvailableThemes.dark
