import { Card, Space } from 'antd'
import {
  EnvironmentOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { formatDate } from '../../../utils/functions/dayjsFunction'

const DoneStep = ({ values }) => {
  return (
    <>
      <div className='mb-5 text-center'>
        <h1 className='m-0 p-0 mb-2' style={{ color: '#002F5E' }}>
          Done
        </h1>
        <div
          style={{
            borderTop: '2px solid #E8256B',
            width: '38px',
            margin: 'auto',
          }}
        />
      </div>
      <div>
        <div>
          <h2 className='mb-4'>
            Please review and confirm your pick up details
          </h2>
        </div>
        <Card>
          <div className='p-2' style={{ background: '#f7f7f7' }}>
            <h3 className='p-0 m-0'>Date and Contact Info</h3>
          </div>
          <div className='mt-4 d-flex'>
            <div className='w-50'>
              <h3>Location and Date:</h3>
              <div className='mb-2'>
                <Space>
                  <EnvironmentOutlined />
                  {values.step2?.address?.street}
                </Space>
              </div>
              <div className='mb-2'>
                <Space>
                  <CalendarOutlined />
                  {formatDate(values.step1?.pickup?.pickupDate, 'ddd, MMMM Do')}
                </Space>
              </div>
              <div className='mb-2'>
                <Space>
                  <ClockCircleOutlined />
                  <span>8 a.m. to 6 p.m.</span>
                </Space>
              </div>
            </div>
            <div>
              <h3>Contact Info:</h3>
              <div className='mb-2'>
                <Space>
                  <UserOutlined />
                  <span>
                    {values.step2?.firstName} {values.step2?.lastName}
                  </span>
                </Space>
              </div>
              <div className='mb-2'>
                <Space>
                  <PhoneOutlined rotate='90' />
                  <span>{values.step2?.phone}</span>
                </Space>
              </div>
              <div className='mb-2'>
                <Space>
                  <MailOutlined />
                  <span>{values.step2?.email}</span>
                </Space>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

export default DoneStep
