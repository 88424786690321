import axios from './axios'
import AbstractApi from './AbstractApi'
import { SCALAR_URL } from './apiConstants'
import { getHeaders } from '../utils/functions/apiUtils'

class Scalar extends AbstractApi {
  constructor() {
    super(SCALAR_URL)
  }

  findAllPrivate = ({ token, params }) =>
    axios.get(`${this.url}/private`, getHeaders(token, params))
}

export default new Scalar()
