import loadable from './utils/functions/loadable'
import {
  GoldOutlined,
  HomeOutlined,
  LoginOutlined,
  UsergroupAddOutlined,
  CarOutlined,
  EnvironmentOutlined,
  ShopOutlined,
  SettingOutlined,
  InboxOutlined,
  BankOutlined,
  GiftOutlined,
  SolutionOutlined,
  MessageOutlined,
  UserOutlined,
  CalendarOutlined,
  LockOutlined,
  FormOutlined,
  CopyOutlined,
  UploadOutlined,
  AimOutlined,
  FileAddOutlined,
  AppstoreOutlined,
  FlagOutlined,
  AuditOutlined,
  UnlockOutlined,
  PlusCircleOutlined,
  CarryOutOutlined,
  GlobalOutlined,
  IssuesCloseOutlined,
  ContainerOutlined,
  SnippetsOutlined,
  NotificationOutlined,
  InteractionOutlined,
  SyncOutlined,
  ProfileOutlined,
  TeamOutlined,
  FileImageOutlined,
  HistoryOutlined,
  BookOutlined,
} from '@ant-design/icons'

const EmptyPage = loadable(() => import('./views/EmptyPage/EmptyPage'))
const Login = loadable(() => import('./views/Login/Login'))
const UsersPage = loadable(() => import('./views/UsersPage'))
const TrucksPage = loadable(() => import('./views/TrucksPage'))
const CollectionLocationsPage = loadable(() =>
  import('./views/CollectionLocationsPage'),
)
const StoresPage = loadable(() => import('./views/StoresPage'))
const DonationCategoriesPage = loadable(() =>
  import('./views/DonationCategoriesPage'),
)
const MessagesPage = loadable(() => import('./views/MessagesPage'))
const HolidaysPage = loadable(() => import('./views/HolidaysPage'))
const PostalCodesPage = loadable(() => import('./views/PostalCodesPage'))
const DriversPage = loadable(() => import('./views/DriversPage'))

const TruckMapPage = loadable(() => import('./views/TruckMapPage'))
const DonorsPage = loadable(() => import('./views/DonorsPage'))
const DonationsPage = loadable(() =>
  import('./views/DonationsPage/DonationsPage'),
)
const DonationPage = loadable(() => import('./views/DonationPage/DonationPage'))
const DonationByLocationPage = loadable(() =>
  import('./views/DonationByLocationPage/DonationByLocationPage'),
)
const HubsPage = loadable(() => import('./views/HubsPage'))
const OrganizationPage = loadable(() => import('./views/OrganizationPage'))
const OrganizationsPage = loadable(() => import('./views/OrganizationsPage'))
const OrganizationalRegionsPage = loadable(() =>
  import('./views/OrganizationalRegionsPage'),
)
const DonationsResultPage = loadable(() =>
  import('./views/DonationsResultPage'),
)
const DonationItemsPage = loadable(() => import('./views/DonationItemsPage'))
const RolesPage = loadable(() => import('./views/RolesPage'))
const OutsManagementPage = loadable(() => import('./views/OutsManagementPage'))
const DialerImportPage = loadable(() =>
  import('./views/DialerImportPage/DialerImportPage'),
)
const CalendarPage = loadable(() => import('./views/CalendarPage'))
const CalendarPickupPage = loadable(() =>
  import('./views/CalendarPickupPage/CalendarPage'),
)
const UnlockPage = loadable(() => import('./views/UnlockPage'))
const RouteCreationPage = loadable(() => import('./views/RouteCreationPage'))
const MapPage = loadable(() => import('./views/MapPage/MapPage'))
const RouteAddOnPage = loadable(() => import('./views/RouteAddOnPage'))
const DonationEntryPage = loadable(() =>
  import('./views/DonationEntryPage/DonationEntryPage'),
)
const DonationEntryItemsPage = loadable(() =>
  import('./views/DonationEntryItemsPage'),
)
const ForecastPage = loadable(() => import('./views/ForecastPage/ForecastPage'))
const DonorForecastPage = loadable(() =>
  import('./views/DonorForecastPage/DonorForecastPage'),
)
const PostalCodeReportPage = loadable(() =>
  import('./views/PostalCodesReportPage'),
)
const RouteManifestPage = loadable(() => import('./views/RouteManifestPage'))
const DialerImportCorrectionPage = loadable(() =>
  import('./views/DialerImportCorrection'),
)
const ZoneManagementPage = loadable(() => import('./views/ZoneManagementPage'))
const ZonePostalCodesPage = loadable(() =>
  import('./views/ZonePostalCodesPage'),
)
const AddressValidationPage = loadable(() =>
  import('./views/AddressValidationPage'),
)
const PickupResultDetails = loadable(() =>
  import('./views/PickupResultsDetails'),
)
const CollectionMethodSummaryPage = loadable(() =>
  import('./views/CollectionMethodSummaryPage/CollectionMethodSummaryPage'),
)
const DonationInformationPage = loadable(() =>
  import('./views/DonationInformationPage'),
)
const EmergencyDonationChangePage = loadable(() =>
  import('./views/EmergencyDonationChangePage'),
)
const EditProfilePage = loadable(() =>
  import('./views/EditProfilePage/EditProfilePage'),
)
const ChangePasswordPage = loadable(() =>
  import('./views/ChangePasswordPage/ChangePasswordPage'),
)
const TBDPage = loadable(() => import('./views/TBDPage'))
const LocationResultPage = loadable(() => import('./views/LocationResultsPage'))
const LocationsPage = loadable(() => import('./views/LocationsPage'))
const RouteManagementPage = loadable(() =>
  import('./views/RouteManagementPage/RouteManagementPage'),
)
const CollectionLocationsHistoryPage = loadable(() =>
  import(
    './views/CollectionLocationsHistoryPage/CollectionLocationsHistoryPage'
  ),
)

const SolicitationsCalendarPage = loadable(() =>
  import('./views/SolicitationsCalendarPage/SolicitationsCalendarPage'),
)
const DailyActivityPage = loadable(() =>
  import('./views/DailyActivityPage/DailyActivityPage'),
)
const InventoryReportPage = loadable(() =>
  import('./views/InventoryReportPage/InventoryReportPage'),
)
const DedicatedDeliveryPlanPage = loadable(() =>
  import('./views/DedicatedDeliveryPlanPage/DedicatedDeliveryPlanPage'),
)

const StatsReportPage = loadable(() =>
  import('./views/StatsReportPage/StatsReportPage'),
)

const OnSiteDeliveryPage = loadable(() =>
  import('./views/OnSiteDeliveryPage/OnSiteDeliveryPage'),
)

const ProductionReportPage = loadable(() =>
  import('./views/ProductionReportPage/ProductionReportPage'),
)

const ProductionDataPage = loadable(() =>
  import('./views/ProductionDataPage/ProductionDataPage'),
)
const PricingPage = loadable(() => import('./views/PricingPage/PricingPage'))
const InventoryPage = loadable(() =>
  import('./views/InventoryPage/InventoryPage'),
)

export const loginPath = '/auth/login'
export const mainDefaultPath = '/main/home'
export const usersPath = '/main/admin/users'
export const trucksPath = '/main/admin/trucks'
export const truckMapPath = '/main/admin/truckMap'
export const collectionLocationsPath = '/main/admin/collectionLocations'
export const storesPath = '/main/admin/deliveryLocation'
export const driversPath = '/main/admin/driver'
export const donationsCategoriesPath = '/main/admin/donationsCategories'
export const messagesPath = '/main/admin/messages'
export const holidaysPath = '/main/admin/holidays'
export const postalCodesPath = '/main/admin/postalCode'
export const donationsPath = '/main/registrations/donations'
export const outsManagementPath = '/main/registrations/outsManagement'
export const donationPath = (donor) => `/main/registrations/donation/${donor}`
export const donationByLocationPath = (location) =>
  `/main/registrations/donationByLocation/${location}`
export const donorsPath = '/main/registrations/donors'
export const dialerImportPath = '/main/registrations/dialerImport'
export const TBDPath = '/main/registrations/tbd'
export const dialerImportCorrectionPath =
  '/main/registrations/dialerImportCorrection'
export const calendarPath = '/main/solicitations/calendar'
export const calendarAddPickupPath = '/main/solicitations/addPickupDate'
export const zoneManagementPath = '/main/solicitations/zoneManagement'
export const zonePostalCodesPath = (zone) =>
  `/main/solicitations/zonePostalCodes/${zone}`
export const forecastPath = '/main/routes/forecast'
export const unlockPath = '/main/routes/unlock'
export const routeCreationPath = '/main/routes/routeCreation'
export const routeManifestPath = '/main/routes/routeManifest'
export const routeAddOnPath = '/main/routes/routeAddOn'
export const donationEntryPath = '/main/routes/donationEntry'
export const donationEntryItemsPath = (route) =>
  `/main/routes/donationItemsEntry/${route}`
export const hubsPath = '/main/config/hubs'
export const mapPath = (route) => `/main/routes/map/${route}`
export const addressValidationPath = '/main/address/addressValidation'
export const emergencyDonationChangePath =
  '/main/address/emergencyDonationChange'
export const organizationsOldPath = '/main/config/organizationsOld'
export const organizationsPath = '/main/config/organizations'
export const organizationalRegionsPath = (organization) =>
  `/main/config/organizationalRegions/${organization}`
export const donationsResultPath = '/main/reports/donationsResult'
export const donorForecastPath = '/main/reports/donorForecast'
export const postalCodeReportPath = '/main/reports/postalCodeReport'
export const pickupResultsDetailsPath = '/main/reports/pickupResultsDetails'
export const locationResultsPath = '/main/reports/collectionLocationResults'
export const donationInformationPath = '/main/reports/donationInformation'
export const donationsItemsPath = (category) =>
  `/main/admin/donationsItems/${category}`
export const rolesPath = '/main/config/roles'
export const settingsPath = '/main/config/settings'
export const editProfilePath = '/main/config/editProfile'
export const changePasswordPath = '/main/config/changePassword'
export const changeOrganizationPath = '/main/config/changeOrganization'
export const locationsPath = '/main/config/locations'
export const routeManagementPath = '/main/routes/routeManagement'
export const collectionLocationsHistoryPath = (id) =>
  `/main/admin/collectionLocationsHistory/${id}`
export const collectionMethodSummaryPath =
  '/main/reports/collection-method-summary'
export const solicitationsCalendarPath = '/main/reports/solicitations-calendar'
export const dailyActivityPath = '/main/reports/daily-activity'
export const inventoryReportPath = '/main/reports/inventory-report'
export const dedicatedDeliveryPlanPath = '/main/admin/dedicated-delivery-plan'
export const statsReportPath = '/main/reports/stats-report'
export const onSiteDeliveryPath = '/main/admin/onsite-delivery-page'
export const productionReportPath = '/main/reports/production-report'
export const productionDataPath = '/main/admin/production-data'
export const pricingPagePath = '/main/config/pricing'
export const inventoryPath = '/main/admin/inventory'

export const homeRoute = {
  name: 'home',
  path: mainDefaultPath,
  icon: <HomeOutlined />,
  component: EmptyPage,
}

export const usersRoute = {
  name: 'users',
  path: usersPath,
  icon: <UsergroupAddOutlined />,
  component: UsersPage,
}

export const trucksRoute = {
  name: 'trucks',
  path: trucksPath,
  icon: <CarOutlined />,
  component: TrucksPage,
}

export const truckMapRoute = {
  name: 'truckMap',
  path: truckMapPath,
  component: TruckMapPage,
  hidden: true,
}
export const postalCodesRoute = {
  name: 'postalCodes',
  path: postalCodesPath,
  icon: <AimOutlined />,
  component: PostalCodesPage,
}

export const donationsItemsRoute = {
  name: 'donationItems',
  path: donationsItemsPath(':categoryId'),
  icon: <GiftOutlined />,
  component: DonationItemsPage,
  hidden: true,
}

export const donationsCategoriesRoute = {
  name: 'donationItems',
  path: donationsCategoriesPath,
  icon: <GiftOutlined />,
  component: DonationCategoriesPage,
}

export const messagesRoute = {
  name: 'messages',
  path: messagesPath,
  icon: <MessageOutlined />,
  component: MessagesPage,
}

export const holidaysRoute = {
  name: 'holidays',
  path: holidaysPath,
  icon: <CalendarOutlined />,
  component: HolidaysPage,
}

export const collectionLocationRoute = {
  name: 'collectionLocations',
  path: collectionLocationsPath,
  icon: <EnvironmentOutlined />,
  component: CollectionLocationsPage,
}

export const storesRoute = {
  name: 'deliveryLocation',
  path: storesPath,
  icon: <ShopOutlined />,
  component: StoresPage,
}

export const driversRoute = {
  name: 'drivers',
  path: driversPath,
  icon: <TeamOutlined />,
  component: DriversPage,
}

export const donationsRoute = {
  name: 'donations',
  path: donationsPath,
  icon: <GiftOutlined />,
  component: DonationsPage,
}

export const donationRoute = {
  name: 'donations',
  path: donationPath(':donorId'),
  icon: <GiftOutlined />,
  component: DonationPage,
  hidden: true,
}

export const donationByLocationRoute = {
  name: 'donations',
  path: donationByLocationPath(':locationId'),
  icon: <GiftOutlined />,
  component: DonationByLocationPage,
  hidden: true,
}

export const donorsRoute = {
  name: 'donors',
  path: donorsPath,
  icon: <SolutionOutlined />,
  component: DonorsPage,
}

export const TBDRoute = {
  name: 'tbd',
  path: TBDPath,
  icon: <ContainerOutlined />,
  component: TBDPage,
}

export const outsManagementRoute = {
  name: 'outsManagement',
  path: outsManagementPath,
  icon: <InboxOutlined />,
  component: OutsManagementPage,
}

export const dialerImportRoute = {
  name: 'dialerImport',
  path: dialerImportPath,
  icon: <UploadOutlined />,
  component: DialerImportPage,
}

export const dialerImportCorrectionRoute = {
  name: 'dialerImportCorrection',
  path: dialerImportCorrectionPath,
  icon: <UploadOutlined />,
  component: DialerImportCorrectionPage,
  hidden: true,
}

export const donationsResultRoute = {
  name: 'donationsResult',
  path: donationsResultPath,
  icon: <GiftOutlined />,
  component: DonationsResultPage,
}

export const donorForecastRoute = {
  name: 'donorForecast',
  path: donorForecastPath,
  icon: <SolutionOutlined />,
  component: DonorForecastPage,
}

export const postalCodeReportRoute = {
  name: 'postalCodeReport',
  path: postalCodeReportPath,
  icon: <AppstoreOutlined />,
  component: PostalCodeReportPage,
}

export const pickupResultsDetailsRoute = {
  name: 'pickupResultsDetails',
  path: pickupResultsDetailsPath,
  icon: <SnippetsOutlined />,
  component: PickupResultDetails,
}

export const collectionMethodSummaryRoute = {
  name: 'collectionMethodSummary',
  path: collectionMethodSummaryPath,
  icon: <SnippetsOutlined />,
  component: CollectionMethodSummaryPage,
}

export const dailyActivityRoute = {
  name: 'dailyActivity',
  path: dailyActivityPath,
  icon: <SnippetsOutlined />,
  component: DailyActivityPage,
}

export const inventoryReportRoute = {
  name: 'inventoryReport',
  path: inventoryReportPath,
  icon: <SnippetsOutlined />,
  component: InventoryReportPage,
}

export const solicitationsCalendarRoute = {
  name: 'solicitationsCalendar',
  path: solicitationsCalendarPath,
  icon: <SnippetsOutlined />,
  component: SolicitationsCalendarPage,
}

export const locationResultsRoute = {
  name: 'collectionLocationReport',
  path: locationResultsPath,
  icon: <FileImageOutlined />,
  component: LocationResultPage,
}

export const donationInformationRoute = {
  name: 'donationInformation',
  path: donationInformationPath,
  icon: <GiftOutlined />,
  component: DonationInformationPage,
}

export const hubsRoute = {
  name: 'hubs',
  path: hubsPath,
  icon: <InboxOutlined />,
  component: HubsPage,
}

export const editProfileRoute = {
  name: 'editProfile',
  path: editProfilePath,
  icon: <ProfileOutlined />,
  component: EditProfilePage,
}

export const changePasswordRoute = {
  name: 'changePassword',
  path: changePasswordPath,
  icon: <SyncOutlined />,
  component: ChangePasswordPage,
}

export const organizationsOldRoute = {
  name: 'organizationsOld',
  path: organizationsOldPath,
  icon: <BankOutlined />,
  component: OrganizationPage,
  hidden: true,
}

export const organizationsRoute = {
  name: 'organizations',
  path: organizationsPath,
  icon: <BankOutlined />,
  component: OrganizationsPage,
}

export const organizationalRegionsRoute = {
  name: 'organizationalRegions',
  path: organizationalRegionsPath(':organizationId'),
  icon: <GiftOutlined />,
  component: OrganizationalRegionsPage,
  hidden: true,
}

export const rolesRoute = {
  name: 'roles',
  path: rolesPath,
  icon: <LockOutlined />,
  component: RolesPage,
}

export const calendarRoute = {
  name: 'calendar',
  path: calendarPath,
  icon: <CalendarOutlined />,
  component: CalendarPage,
}

export const calendarPickupRoute = {
  name: 'calendar',
  path: calendarAddPickupPath,
  icon: <CalendarOutlined />,
  component: CalendarPickupPage,
  hidden: true,
}

export const zoneManagementRoute = {
  name: 'zones',
  path: zoneManagementPath,
  icon: <AppstoreOutlined />,
  component: ZoneManagementPage,
}

export const zonePostalCodesRoute = {
  name: 'zones',
  path: zonePostalCodesPath(':zoneId'),
  icon: <GiftOutlined />,
  component: ZonePostalCodesPage,
  hidden: true,
}

export const forecastRoute = {
  name: 'forecast',
  path: forecastPath,
  icon: <AuditOutlined />,
  component: ForecastPage,
}

export const unlockRoute = {
  name: 'unlock',
  path: unlockPath,
  icon: <UnlockOutlined />,
  component: UnlockPage,
}

export const dedicatedDeliveryPlanRoute = {
  name: 'dedicatedDeliveryPlan',
  path: dedicatedDeliveryPlanPath,
  icon: <BookOutlined />,
  component: DedicatedDeliveryPlanPage,
}

export const onSiteDeliveryRoute = {
  name: 'onSiteDelivery',
  path: onSiteDeliveryPath,
  icon: <BookOutlined />,
  component: OnSiteDeliveryPage,
}

export const productionReportRoute = {
  name: 'productionReport',
  path: productionReportPath,
  icon: <BookOutlined />,
  component: ProductionReportPage,
}

export const productionDataRoute = {
  name: 'productionData',
  path: productionDataPath,
  icon: <BookOutlined />,
  component: ProductionDataPage,
}

export const pricingRoute = {
  name: 'pricing',
  path: pricingPagePath,
  icon: <BookOutlined />,
  component: PricingPage,
}

export const inventoryRoute = {
  name: 'inventory',
  path: inventoryPath,
  icon: <GoldOutlined />,
  component: InventoryPage,
}

export const statsReportRoute = {
  name: 'statsReport',
  path: statsReportPath,
  icon: <SnippetsOutlined />,
  component: StatsReportPage,
}

export const routeCreationRoute = {
  name: 'routeCreation',
  path: routeCreationPath,
  icon: <PlusCircleOutlined />,
  component: RouteCreationPage,
}

export const routeManagementRoute = {
  name: 'routeManagement',
  path: routeManagementPath,
  icon: <CarOutlined />,
  component: RouteManagementPage,
}

export const routeAddOnRoute = {
  name: 'routeAddOn',
  path: routeAddOnPath,
  icon: <ContainerOutlined />,
  component: RouteAddOnPage,
}

export const routeManifestRoute = {
  name: 'routeManifest',
  path: routeManifestPath,
  icon: <CarryOutOutlined />,
  component: RouteManifestPage,
}

export const donationEntryRoute = {
  name: 'donationEntry',
  path: donationEntryPath,
  icon: <NotificationOutlined />,
  component: DonationEntryPage,
}

export const donationEntryItemsRoute = {
  name: 'donationEntry',
  path: donationEntryItemsPath(':routeId'),
  icon: <GiftOutlined />,
  component: DonationEntryItemsPage,
  hidden: true,
}

export const mapRoute = {
  name: 'map',
  path: mapPath(':routeId'),
  component: MapPage,
  hidden: true,
}

export const addressValidationRoute = {
  name: 'addressValidation',
  path: addressValidationPath,
  icon: <IssuesCloseOutlined />,
  component: AddressValidationPage,
}

export const emergencyDonationChangeRoute = {
  name: 'donationEmergencyChange',
  path: emergencyDonationChangePath,
  icon: <InteractionOutlined />,
  component: EmergencyDonationChangePage,
}

export const locationsRoute = {
  name: 'locations',
  path: locationsPath,
  icon: <EnvironmentOutlined />,
  component: LocationsPage,
}

export const collectionLocationsHistoryRoute = {
  name: 'collectionLocationsHistory',
  path: collectionLocationsHistoryPath(':id'),
  icon: <HistoryOutlined />,
  component: CollectionLocationsHistoryPage,
  hidden: true,
}

// export const settingsRoute = {
//   name: 'settings',
//   path: settingsPath,
//   icon: <ToolOutlined />,
//   component: SettingsPage,
// }

const adminRoute = {
  name: 'admin',
  path: '/main/admin',
  icon: <UserOutlined />,
  subMenuRoutes: [
    usersRoute,
    trucksRoute,
    collectionLocationRoute,
    collectionLocationsHistoryRoute,
    storesRoute,
    donationsItemsRoute,
    donationsCategoriesRoute,
    messagesRoute,
    holidaysRoute,
    postalCodesRoute,
    truckMapRoute,
    driversRoute,
    dedicatedDeliveryPlanRoute,
    onSiteDeliveryRoute,
    productionDataRoute,
    inventoryRoute,
  ],
}

const registrationsRoute = {
  name: 'registrations',
  path: '/main/registrations',
  icon: <FormOutlined />,
  subMenuRoutes: [
    donationsRoute,
    donationRoute,
    donationByLocationRoute,
    donorsRoute,
    dialerImportRoute,
    dialerImportCorrectionRoute,
    outsManagementRoute,
    TBDRoute,
  ],
}

const solicitationsRoute = {
  name: 'solicitations',
  path: '/main/solicitations',
  icon: <FileAddOutlined />,
  subMenuRoutes: [
    calendarRoute,
    calendarPickupRoute,
    // zoneManagementRoute,
    zonePostalCodesRoute,
  ],
}

const routesRoute = {
  name: 'routes',
  path: '/main/routes',
  icon: <FlagOutlined />,
  subMenuRoutes: [
    forecastRoute,
    unlockRoute,
    routeManagementRoute,
    routeCreationRoute,
    routeManifestRoute,
    routeAddOnRoute,
    donationEntryRoute,
    donationEntryItemsRoute,
    mapRoute,
  ],
}

const reportsRoute = {
  name: 'reports',
  path: '/main/reports',
  icon: <CopyOutlined />,
  subMenuRoutes: [
    // donationsResultRoute,
    donorForecastRoute,
    postalCodeReportRoute,
    pickupResultsDetailsRoute,
    donationInformationRoute,
    locationResultsRoute,
    collectionMethodSummaryRoute,
    solicitationsCalendarRoute,
    dailyActivityRoute,
    inventoryReportRoute,
    statsReportRoute,
    productionReportRoute,
  ],
}

const addressRoute = {
  name: 'address',
  path: '/main/address',
  icon: <GlobalOutlined />,
  subMenuRoutes: [addressValidationRoute, emergencyDonationChangeRoute],
}

const configRoute = {
  name: 'settings',
  path: '/main/config',
  icon: <SettingOutlined />,
  subMenuRoutes: [
    hubsRoute,
    rolesRoute,
    organizationsRoute,
    editProfileRoute,
    locationsRoute,
    changePasswordRoute,
    organizationalRegionsRoute,
    pricingRoute,
  ],
}

export const mainRoutes = [
  homeRoute,
  adminRoute,
  registrationsRoute,
  solicitationsRoute,
  routesRoute,
  reportsRoute,
  addressRoute,
  configRoute,
]

/**
 * Use mx-auto on icons to prevent responsive errors just if route is not
 */
export const authRoutes = [
  {
    name: 'login',
    path: loginPath,
    icon: <LoginOutlined className='mx-auto' />,
    component: Login,
    // hidden: true
  },
]
