import { produce } from 'immer'
import {
  SET_LOCALE,
  SET_ORGANIZATION,
  SET_REGION,
  SET_REFRESH_DATA,
  SET_THEME,
} from './constants'

const initialState = {
  locale: 'en',
  organization: '',
  region: '',
  isRefresh: false,
}

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_LOCALE:
        draft.locale = action.locale
        break
      case SET_REFRESH_DATA:
        draft.isRefresh = action.isRefresh
        break
      case SET_ORGANIZATION:
        draft.organization = action.organization
        break
      case SET_REGION:
        draft.region = action.region
        break
      case SET_THEME:
        draft.theme = action.theme
        break
      default:
        break
    }
  })

export default reducer
